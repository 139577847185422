<template>
    <div class="grid">
        <Divider />
        <div class="col-1 col-offset-1">
            <div class="card flex justify-content-center">
                <Slider v-model="diameter" :step="10" class="h-14rem" orientation="vertical"
                    v-on:change="update_diameter()" />
            </div>
            <InputText type="text" v-model="diameterMeters" class="w-8rem" />
            <Divider />
            <Dropdown v-model="time" :options="times" optionLabel="name" placeholder="Select a Time"
                class="w-full md:w-8rem" />
        </div>
        <div class="col-8">
            <div id="map" style="height:450px;"></div>
        </div>
    </div>
    <div class="grid">
        <TopDetail name="LSA" :tops="topLsas" :loading="is_lsa_loading()" />
        <TopDetail name="ADS" :tops="topAds" :loading="is_ads_loading()" />
        <TopDetail name="GMB" :tops="topGmbs" :loading="is_gmb_loading()" />
    </div>
</template>

<script>

import Divider from 'primevue/divider';
import Slider from 'primevue/slider';
import InputText from 'primevue/inputtext';
import { ref } from 'vue';
import api from '../api'
import TopDetail from '../components/TopDetail.vue'
import Dropdown from 'primevue/dropdown';

var map
var circle
export default {
    name: 'mapExplorer',
    props: {
    },
    components: { Divider, Slider, InputText, TopDetail, Dropdown },
    data() {
        return {
            diameter: ref(20),
            diameterMeters: null,
            topAds: null,
            topLsas: null,
            topGmbs: null,
            loading: false,
            times:  [
                        { name: 'Last 3 Months', code: '90' },
                        { name: 'Last Month', code: '30' },
                        { name: 'Last Week', code: '7' }
                    ],
            time: null,
        }
    }, mounted() {
        this.time = this.times[1]
        this.update_diameter()
        map = new window.google.maps.Map(document.getElementById('map'), {
            center: { lat: 39.666599, lng: -99.140855 },
            zoom: 4,
            mapId: "4504f8b37365c3d0",
        });
        map.addListener("click", (e) => {
            if (circle) {
                circle.setMap(null)
            }
            map.setCenter(e.latLng)
            map.setZoom(10)
            circle = new window.google.maps.Circle({
                strokeColor: "#FF0000",
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: "#FF0000",
                fillOpacity: 0.35,
                map,
                center: e.latLng,
                radius: this.diameterMeters,
            });
            this.loading = true
            this.topAds = null
            this.topGmbs = null
            this.topLsas = null
            this.load_top_lsas(e)
            this.load_top_ads(e)
            this.load_top_gmbs(e)
        });

    }, methods: {
        is_lsa_loading() {
            return this.loading == true && this.topLsas == null
        },
        is_ads_loading() {
            return this.loading == true && this.topAds == null
        },
        is_gmb_loading() {
            return this.loading == true && this.topGmbs == null
        },
        is_loaded() {
            if (this.topAds != null && this.topGmbs != null && this.topLsas != null) {
                this.loading = false
            }
        },
        get_args(e){
            return { args: { lon: e.latLng.lng(), lat: e.latLng.lat(), radius: this.diameterMeters, time: this.time['code'] } }
        }, 
        update_diameter() {
            this.diameterMeters = this.diameter * 200
        },
        async load_top_lsas(e) {
            api.post(process.env.VUE_APP_API_HOST + 'api_ui/lsa/toplsabyarea', this.get_args(e))
                .then((response) => {
                    this.topLsas = response.data['data']; this.is_loaded();
                })
        },
        async load_top_ads(e) {
            api.post(process.env.VUE_APP_API_HOST + 'api_ui/gsearch/topadsbyarea', this.get_args(e))
                .then((response) => {
                    this.topAds = response.data['data']; this.is_loaded();
                })
        },
        async load_top_gmbs(e) {
            api.post(process.env.VUE_APP_API_HOST + 'api_ui/map/topgmbbyarea', this.get_args(e))
                .then((response) => {
                    this.topGmbs = response.data['data']; this.is_loaded();
                })
        }
    }
};
</script>
