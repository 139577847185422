<template>
  <div id="app" class="card">
    <Toast />
    <navbar v-if="$store.state.access_token"></navbar>
    <router-view></router-view>
  </div>
</template>

<script>
import Navbar from './components/Navbar';
import Toast from 'primevue/toast';
export default {
  name: 'app',
  data() {
        return {
        }
    },
  components: {
    Navbar,Toast
  },
  mounted(){
    this.$store.commit('initialiseStore')
  },
};
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
