<template>
    <div class="card">
        <Menubar :model="items" />
    </div>
</template>

<script>
import router from '@/router/index';
import Menubar from 'primevue/menubar';
import { ref } from "vue";

export default {
    name: 'navbar',
    components: { Menubar },
    props: ['loading'],
    data() {
        return {
            items: ref([
                {
                    label: 'Google',
                    icon: 'pi pi-fw pi-map',
                    items: [
                        {
                            label: 'Map',
                            to: '/map'

                        }
                    ]
                },
                {
                    label: 'LSA',
                    icon: 'pi pi-fw pi-map',
                    items: [
                        {
                            label: 'Map',
                            to: '/lsamap'

                        },
                        {
                            label: 'Competitors',
                            to: '/lsa'
                        },
                        {
                            label: 'Trend',
                            to: '/lsatrend'
                        }
                    ]
                },
                {
                    label: 'GMB',
                    icon: 'pi pi-fw pi-map',
                    items: [
                        {
                            label: 'Map',
                            to: '/gmbmap'

                        }
                    ]
                },
                {
                    label: 'Explorer',
                    icon: 'pi pi-fw pi-map',
                    items: [
                        {
                            label: 'Map',
                            to: '/mapexplorer'

                        }
                    ]
                }, {
                    label: 'Logout',
                    icon: 'pi pi-times',
                    command: () => { this.logout() }
                }
            ])
        }
    },
    methods: {
        logout() {
            this.$store.commit('logout')
            router.push('/login')
        }

    }
};
</script>
