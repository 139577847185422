<template>
    <div class="col-8 col-offset-2">
        <template v-if="tops != null">
            <h2>{{name}}</h2>
            <DataTable :value="tops" tableStyle="min-width: 50rem" scrollable scrollHeight="300px"
                :virtualScrollerOptions="{ itemSize: 12 }">
                <Column field="domain" header="Domain"></Column>
                <Column field="title" header="Title"></Column>
                <Column field="rank" header="Rank"></Column>
                <Column field="count" header="Count"></Column>
            </DataTable>
        </template>
        <template v-if="loading">
            <ProgressSpinner />
        </template>
    </div>
</template>

<script>

import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ProgressSpinner from 'primevue/progressspinner';

export default {
    name: 'topDetail',
    components: { DataTable, Column, ProgressSpinner },
    props: ['tops', 'name', 'loading'],
    data() {
        return {
        }
    },
    methods: {}
};
</script>
