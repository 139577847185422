<template>
    <div class="grid">
        <div class="col-3 col-offset-2">
            <Dropdown v-model="selectedDomain" :options="domains" filter
                optionLabel="name" placeholder="Select a Domain" class="w-full md:w-14rem" 
                @change="$emit('update:modelValue', selectedDomain['name'])"/>
        </div>
    </div>
</template>

<script>

import api from '../api'
import Dropdown from 'primevue/dropdown';

export default {
    name: 'domain',
    components: {Dropdown},
    props: ['modelValue'],
    emits: ['update:modelValue'],
    data() {
        return {
            selectedDomain : null,
            domains : null
        }
    },
    mounted() {
        this.get_domains()
    },
    methods: {
        get_domains() {
            api.get(process.env.VUE_APP_API_HOST + 'api_ui/domains')
                .then(response => { this.domains = response.data['data'] })
        }
    }
};
</script>
