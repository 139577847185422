<template>
    <DataTable v-if="pointDetails" :value="pointDetails" stripedRows>
        <Column field="rank" header="Rank">
            <template #body="{ data }">
                <template v-if="data.domain == this.domain">
                    <i class="font-bold">
                        {{ data.rank }}
                    </i>
                </template>
                <template v-else>
                    {{ data.rank }}
                </template>
            </template>
        </Column>
        <Column field="domain" header="domain">
            <template #body="{ data }">
                <template v-if="data.domain == this.domain">
                    <i class="font-bold">
                        {{ data.domain }}
                    </i>
                </template>
                <template v-else>
                    {{ data.domain }}
                </template>
            </template>
        </Column>
        <Column field="title" header="title">
            <template #body="{ data }">
                <template v-if="data.domain == this.domain">
                    <i class="font-bold">
                        {{ data.title }}
                    </i>
                </template>
                <template v-else>
                    {{ data.title }}
                </template>
            </template>
        </Column>
    </DataTable>
</template>

<script>

import DataTable from 'primevue/datatable';
import Column from 'primevue/column';

export default {
    name: 'pointDetail',
    components: { DataTable, Column },
    props: ['pointDetails', 'domain'],
    data() {
        return {
        }
    },
    methods: {}
};
</script>
