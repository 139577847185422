<template>
    <div class="grid mt-5">
        <div class="col-2 col-offset-4"><InputText type="text" v-model="username" /></div>
        <div class="col-2 "><Password v-model="password" :feedback="false" /></div>
        <Button @click="login" :loading="loading" type="button" icon="pi pi-bolt" label="Login"/>
    </div>
</template>

<script>
import axios from 'axios'
import router from '@/router/index';
import InputText from 'primevue/inputtext';
import Password from 'primevue/password';
import Button from 'primevue/button';
export default {
    components : {InputText, Password, Button},
    data() {
        return {
            username: "",
            password: "",
            loading: false
        }
    }, props: {
    },
    methods: {
        login() {
            this.loading = true
            axios.post(process.env.VUE_APP_API_HOST + 'o/token/', 'grant_type=password&username=' + this.username + '&password=' + this.password, { auth: { username: 'front_end', password: '' } })
                .then((response) => {
                    this.$store.commit('login', response.data)
                })
                .then(() => { router.push('/') })
        }
    }
}
</script>
