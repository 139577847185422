import { createWebHistory, createRouter } from "vue-router";
import Login from "@/views/Login.vue";
import IntMap from "@/views/IntMap.vue";
import LsaMap from "@/views/LsaMap.vue";
import Lsa from "@/views/Lsa.vue";
import LsaTrend from "@/views/LsaTrend.vue";
import GmbMap from "@/views/GmbMap.vue";
import PublicLsaMap from "@/views/PublicLsaMap.vue";
import MapExplorer from "@/views/MapExplorer.vue"
import store from '@/store/index';

const routes = [
  {
    path: "/",
    name: "Home",
    component: IntMap,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/map",
    name: "IntMap",
    component: IntMap,
  },
  {
    path: "/lsamap",
    name: "LsaMap",
    component: LsaMap,
  },
  {
    path: "/lsa",
    name: "Lsa",
    component: Lsa,
  },
  {
    path: "/lsatrend",
    name: "Lsa Trend",
    component: LsaTrend,
  },
  {
    path: "/gmbmap",
    name: "GmbMap",
    component: GmbMap,
  },
  {
    path: "/publiclsamap/:uid",
    name: "PublicLsaMap",
    component: PublicLsaMap,
  },
  {
    path: "/mapexplorer",
    name: "MapExplorer",
    component: MapExplorer,
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to) => {
    if (store.state.username === "" && to.name !== 'Login') {
        return { name: 'Login' };
    }
});

export default router;