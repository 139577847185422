<template>
    <Map mapType="GSEARCH"/>
</template>

<script>
import Map from '../components/Map.vue'

export default {
    name: 'LsaMap',
    components: { Map }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>