<template>
    <div class="grid">
        <div class="xl:col-8 xl:col-offset-2 sm:col-12 sm:col-offset-0">
            <template v-if="is_public() == false">
                <div class="grid text-center mt-5">
                    <div class="grid">
                        <div class="col-8">
                            <div class="grid text-left">
                                <div class="col-12">
                                    <Dropdown v-model="selectedDomain" v-on:change="load_dates(); load_phone_numbers()"
                                        :options="domains" filter optionLabel="name" placeholder="Select a Domain"
                                        class="w-full md:w-14rem" />
                                </div>
                                <template v-if="is_lsa()">
                                    <div class="col-12">
                                        <Dropdown :disabled="phoneNumbers == null" v-model="selectedPhoneNumber"
                                            v-on:change="load_dates()" :options="phoneNumbers" filter optionLabel="phone"
                                            placeholder="Select a Phone number" class="w-full md:w-14rem" />
                                    </div>
                                </template>
                                <div class="col-12">
                                    <SelectButton v-model="selectedQuery" :options="queries" optionLabel="name"
                                        aria-labelledby="multiple" v-on:change="load_dates()" />
                                </div>
                                <template v-if="is_google_search()">
                                    <div class="col-12">
                                        <SelectButton v-model="selectedType" :options="types" optionLabel="name"
                                            aria-labelledby="multiple" v-on:change="load_dates()" />
                                    </div>
                                </template>
                                <div class="col-12">
                                    <datepicker v-model="selectedDate" :highlighted="state.highlighted" />
                                </div>
                            </div>
                        </div>
                        <div class="col-4 text-center py-8">
                            <Button type="button" icon="pi pi-search" :loading="loading" label="Generate Map"
                                @click="load_query()" />
                        </div>
                    </div>
                </div>
            </template>
            <div class="grid text-center mt-5">
                <template v-if="is_public()">
                    <div class="col-6 col-offset-3">
                        <Card>
                            <template #header>
                                <img alt="Eclipse" src="/img/eclipse-logo-tiny.png" />
                            </template>
                            <template #title> LSA map </template>
                            <template #subtitle> {{ publicMapName }}</template>
                        </Card>
                    </div>
                    <div class="col-1">
                        <ProgressSpinner v-if="loading" />
                    </div>
                    <Divider type="solid" />
                </template>
                <div class="col-12">
                    <div id="map" class="gmap"></div>
                </div>
            </div>


            <template v-if="(is_lsa() && is_public() == false) || this.is_map()">
                <Dialog v-model:visible="dialogVisible" modal header="Location details" :style="{ width: '70vw' }">
                    <Card>
                        <template #title> {{ pointLocation }} </template>
                        <template #subtitle> {{ pointZip }}</template>
                    </Card>
                    <div class="grid text-center mt-5 h-25rem">
                        <div class="col-6">
                            <ProgressSpinner v-if="pointDetails == null" />
                            <pointDetail :pointDetails="pointDetails" :domain="selectedDomain['name']" />
                        </div>
                        <div class="col-6">
                            <ProgressSpinner v-if="chartData == null" />
                            <Line v-if="chartData" id="my-chart-id" :options="chartOptions" :data="chartData" />
                        </div>
                    </div>
                </Dialog>

            </template>
        </div>
    </div>
</template>

<script>
import api from '../api'
import pointDetail from '../components/PointDetail.vue'
import Datepicker from 'vuejs3-datepicker'
import Dropdown from 'primevue/dropdown';
import SelectButton from 'primevue/selectbutton';
import Button from 'primevue/button';
import { Line } from 'vue-chartjs'
import ProgressSpinner from 'primevue/progressspinner';
import Divider from 'primevue/divider';
import Card from 'primevue/card';
import Dialog from 'primevue/dialog';
import { error } from './myToastService.js';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js'

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
)

var map
var markers
var marker

export default {
    name: 'map',
    props: {
        mapType: {
            type: String
        },
        public: {
            type: Boolean
        }
    },
    components: { pointDetail, Datepicker, Line, Dropdown, SelectButton, Button, ProgressSpinner, Divider, Card, Dialog },
    data() {
        return {
            publicMapName: null,
            loading: true,
            hasData: false,
            domains: [],
            queries: null,
            type: null,
            types: null,
            selectedDomain: null,
            selectedLocations: null,
            selectedQuery: null,
            selectedType: null,
            message: null,
            pointDetails: null,
            pointLocation: null,
            pointZip: null,
            chartData: null,
            selectedDate: null,
            datePickerDisabled: true,
            phoneNumbers: null,
            selectedPhoneNumber: null,
            state: {
                highlighted: {},
            },
            chartOptions: {
                responsive: true,
                scales: {
                    y: {
                        reverse: true
                    }
                }
            },
            dialogVisible: false
        }
    }, mounted() {
        this.load()
        map = new window.google.maps.Map(document.getElementById('map'), {
            center: { lat: 39.666599, lng: -99.140855 },
            zoom: 5,
            mapId: "4504f8b37365c3d0",
        });
        markers = []
        if (this.is_public()) {
            this.load_query()
        } else {
            this.get_domains()
            this.get_queries()
            if (this.is_google_search()) {
                this.get_types()
            }
        }
    }, methods: {
        async load_dates() {
            if (this.selectedDomain == null || this.selectedQuery == null || (this.is_google_search() && this.selectedType == null)) {
                return
            }
            this.load()
            api.post(process.env.VUE_APP_API_HOST + 'api_ui/sqldate', this.get_args())
                .then(response => {
                    const data = response.data['data']
                    if (data.length == 0) {
                        error('Error', 'No data found !')
                        this.state.highlighted = {
                            dates: []
                        }
                    } else {
                        const dates = []
                        for (let index = 0; index < data.length; index++) {
                            dates.push(new Date(Date.parse(data[index] + 'T14:00:00')))
                        }
                        this.state.highlighted = {
                            dates: dates
                        }
                        this.datePickerDisabled = false
                    }
                    this.loaded()
                })
                .catch((reason) => {
                    error('Something went wrong', reason)
                })
        },
        async load_phone_numbers() {
            if (this.selectedDomain == null) {
                return
            }
            const response = await api.post(process.env.VUE_APP_API_HOST + 'api_ui/lsa/phonenumbers', { 'args': { 'domain': this.selectedDomain['name'] } });
            this.phoneNumbers = response.data['data']
        },
        is_lsa() {
            return this.mapType == 'LSA'
        },
        is_google_search() {
            return this.mapType == 'GSEARCH'
        },
        is_map() {
            return this.mapType == 'MAP'
        },
        is_public() {
            return this.public
        },
        load() {
            this.loading = true
        },
        loaded() {
            this.loading = false
        },
        get_domains() {
            api.get(process.env.VUE_APP_API_HOST + 'api_ui/domains')
                .then(response => { this.domains = response.data['data'] })
        },
        get_queries() {
            let endpoint = null
            if(this.is_map()) {
                endpoint = 'api_ui/map/queries'
            } else if(this.is_lsa()) {
                endpoint = 'api_ui/lsa/queries'
            } else if(this.is_google_search()){
                endpoint = 'api_ui/queries'
            }
            api.get(process.env.VUE_APP_API_HOST + endpoint)
            .then(response => { this.queries = response.data['data']; this.loaded() })                
        },
        get_types() {
            api.get(process.env.VUE_APP_API_HOST + 'api_ui/types')
                .then(response => { this.types = response.data['data'] })
        },
        async get_point_details(args) {
            const response = await api.post(process.env.VUE_APP_API_HOST + 'api_ui/sqlmap/pointdetails', args);
            this.pointDetails = response.data['data']
        },
        async get_point_trend(args) {
            const response = await api.post(process.env.VUE_APP_API_HOST + 'api_ui/sqlmap/pointtrend', args);
            this.chartData = response.data['data']
        },
        get_args() {
            let formatedDate = null
            if (this.selectedDate) {
                formatedDate = this.selectedDate.getFullYear() + '-' + (this.selectedDate.getMonth() + 1) + '-' + this.selectedDate.getDate()
            }
            return {
                'type': this.mapType,
                'args': {
                    'mapType': this.mapType,
                    'domain': this.selectedDomain['name'],
                    'processed_at': formatedDate,
                    'query': this.selectedQuery['name'],
                    'type': this.selectedType == null ? null : this.selectedType['name'],
                    'phone': this.selectedPhoneNumber == null ? null : this.selectedPhoneNumber['phone']
                }
            }
        },
        reset_map() {
            if (markers == null) {
                return
            }
            for (let index = 0; index < markers.length; index++) {
                markers[index].map = null
            }
            markers.length = 0
        },
        currentRouteName() {
            return this.$route.params['uid'];
        },
        load_query() {
            if (this.is_public() == false && (this.selectedDomain == null || this.selectedQuery == null || this.selectedDate == null)) {
                return
            }
            this.load()
            this.reset_map()
            let url = process.env.VUE_APP_API_HOST
            let args = {}
            let method = null
            if (this.is_public()) {
                url = url + 'api_ui/public/map/' + this.currentRouteName()
                method = 'get'
            } else {
                method = 'post'
                url = url + 'api_ui/sqlmap'
                args = this.get_args()
            }
            api.request({ method: method, url: url, data: args })
                .then(response => {

                    const data = response.data['data']
                    this.loaded()
                    if (data.length == 0) {
                        error('Error', 'No data found !')
                    } else {
                        if ('map' in response.data) {
                            this.publicMapName = response.data['map']['name']
                            //warning('Expiration', 'This map won\'t be accessible after ' + response.data['map']['exp'])
                        }
                        this.message = null
                        for (let index = 0; index < data.length; index++) {
                            const element = data[index];
                            let tag = document.createElement("div");
                            tag.className = "zoom_8 gradient_" + element['gradient'];
                            tag.textContent = element['rank'];

                            markers[index] = new window.google.maps.marker.AdvancedMarkerView({
                                map,
                                position: { lat: element.lat, lng: element.lon },
                                content: tag
                            })
                            if ((this.is_lsa() && this.is_public() == false) || this.is_map()) {
                                markers[index].addListener('click', () => {
                                    this.dialogVisible = true

                                    if (marker) {
                                        marker.content.classList.remove("highlight");
                                    }
                                    marker = markers[index]
                                    marker.content.classList.add("highlight")
                                    args = this.get_args()
                                    args['args']['lon'] = element.lon
                                    args['args']['lat'] = element.lat
                                    this.chartData = this.pointDetails = null
                                    this.get_point_details(args)
                                    this.get_point_trend(args)
                                })
                            }
                        }
                        map.addListener('zoom_changed', () => {
                            let zoom = map.getZoom()
                            let new_class = "zoom_" + zoom
                            markers.map(function(element) {
                                
                                for (const css_class of element.content.classList.values()) {
                                    if(css_class.match('zoom_\\d+')){
                                        element.content.classList.remove(css_class);
                                    }
                                }
                                element.content.classList.add(new_class);
                                
                            })
                        });
                    }
                })
                .catch((reason) => {
                    this.loaded()
                    error('Something went wrong', reason)
                })

        }
    }
};
</script>
