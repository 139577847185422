<template>
    <Domain v-model="selectedDomain" :loading="loading"/>
    <div class="grid">
        <template v-for="location in competitors" :key="location.location">
            <div class="col-6">
                <Card>
                    <template #title> {{ location.location }} : {{ location.rank }} </template>
                    <template #content>
                        <DataTable :value="location.competitors" scrollHeight="400px" scrollable :virtualScrollerOptions="{ itemSize: 5 }">
                            <Column field="domain" header="domain"></Column>
                            <Column field="rank" header="rank"></Column>
                        </DataTable>
                    </template>
                </Card>
            </div>
        </template>
    </div>
</template>

<script>
import Domain from '../components/Domain.vue'
import Card from 'primevue/card';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import api from '../api'

export default {
    name: 'Lsa',
    components: { Domain, Card, DataTable, Column },
    data() {
        return {
            selectedDomain: null,
            competitors: null,
            loading: false
        }
    },
    watch: {
        selectedDomain() {
            this.competitors = null
            this.get_competitors()
        }
    },
    methods: {
        get_competitors() {
            this.loading = true
            api.post(process.env.VUE_APP_API_HOST + 'api_ui/lsa/competitors', { 'args': { 'domain': this.selectedDomain } })
                .then(response => { this.competitors = response.data['data']; this.loading = false })
        }
    }
};
</script>
