<template>
    <Divider />
    <Domain v-model="selectedDomain" :loading="loading" />
    <Divider />
    <div class="grid">
        <template v-if="service_areas != null">
            <div class="col-2 col-offset-2">
                <Dropdown v-model="selectedServiceArea" :options="service_areas" filter optionLabel="service_area"
                    placeholder="Select a service area" class="w-full md:w-14rem" />
            </div>
            <div class="col-2">
                <Dropdown v-model="selectedTime" :options="times" optionLabel="name" placeholder="Select a period"
                    class="w-full md:w-14rem" />
            </div>
            <div class="col-2">
                <Button :disabled="selectedTime == null" type="button" icon="pi pi-search" :loading="loading" label="Go"
                    @click="load_query()" />
            </div>
        </template>
    </div>
    <template v-if="trends != null">
        <div class="grid">
            <template v-for="trend in trends" :key="trend.name">
                <!-- <template v-for="d in trend.data" :key="d.num"> -->
                <div class="col-3">
                    <Card>

                        <template #subtitle> {{ trend.name }} </template>
                        <template #content>
                            <Chart type="line" :data="trend" :options="chartOptions" class="h-18rem" />
                            <!-- <template v-if="d.trend == '+'">
                                    <i class="pi pi-arrow-up-right" style="color: green;font-size: 2.5rem"></i>
                                </template>
                                <template v-else-if="d.trend == '-'">
                                    <i class="pi pi-arrow-down-right" style="color: red;font-size: 2.5rem"></i>
                                </template>
                                <template v-else-if="d.trend == '='">
                                    <i class="pi pi-arrow-right" style="color: black;font-size: 2.5rem"></i>
                                </template> -->
                        </template>
                    </Card>
                    <!-- </template> -->
                </div>
            </template>
        </div>
    </template>
</template>

<script>
import Domain from '../components/Domain.vue'
import api from '../api'
import Dropdown from 'primevue/dropdown';
import Button from 'primevue/button';
import Card from 'primevue/card';
import Divider from 'primevue/divider';
import Chart from 'primevue/chart';

import { ref } from "vue";
import { error } from '../components/myToastService.js';

const documentStyle = getComputedStyle(document.documentElement);

export default {
    name: 'Lsa',
    components: { Domain, Dropdown, Button, Card, Divider, Chart },
    data() {
        return {
            selectedDomain: null,
            loading: false,
            service_areas: null,
            selectedServiceArea: null,
            selectedTime: null,
            trends: null,
            times: ref([
                { name: 'Daily', code: 'DAY' },
                { name: 'Weekly', code: 'WEEK' },
                { name: 'Monthly', code: 'MONTH' }
            ]),
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                aspectRatio: 0.6,
                plugins: {
                    legend: {
                        labels: {
                            color: documentStyle.getPropertyValue('--text-color')
                        }
                    }
                },
                scales: {
                    x: {
                        ticks: {
                            color: documentStyle.getPropertyValue('--text-color-secondary')
                        },
                        grid: {
                            color: documentStyle.getPropertyValue('--surface-border')
                        }
                    },
                    y: {
                        min: 0,
                        max: 50,
                        beginAtZero: true,
                        reverse: true,
                        ticks: {
                            color: documentStyle.getPropertyValue('--text-color-secondary')
                        },
                        grid: {
                            color: documentStyle.getPropertyValue('--surface-border')
                        }
                    }
                }
            }
        }
    },
    mounted() {
    },
    watch: {
        selectedDomain() {
            api.post(process.env.VUE_APP_API_HOST + 'api_ui/lsa/serviceareas', { 'args': { 'domain': this.selectedDomain } })
                .then((response) => { this.service_areas = response.data['data']; })
        }
    },
    methods: {
        load_query() {
            this.loading = true
            api.post(process.env.VUE_APP_API_HOST + 'api_ui/lsa/locationtrendschart',
                {
                    'args':
                    {
                        'domain': this.selectedDomain,
                        'time': this.selectedTime,
                        'service_area': this.selectedServiceArea == null ? null : this.selectedServiceArea['service_area'],
                    }
                }
            )
                .then((response) => { this.trends = response.data['data']; this.loading = false })
                .catch((message) => { error(message); this.loading = false })
        }
    }
};
</script>
