import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index'
import store from './store'

import "./assets/gradients.css";

import api from "./api"
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import axiosRetry from 'axios-retry';
import PrimeVue from 'primevue/config';
import ToastService from 'primevue/toastservice';
//theme
import "primevue/resources/themes/lara-light-indigo/theme.css";     
    
//core
import "primevue/resources/primevue.min.css";

//icons
import "primeicons/primeicons.css";
api.interceptors.request.use(
    config => {
        if (store.getters.access_token) {
            config.headers["Authorization"] = 'Bearer ' + store.getters.access_token;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

const refreshAuthLogic = (failedRequest) => {
    api.post('o/token/',
    'grant_type=refresh_token&client_id=front_end&refresh_token=' + store.getters.refresh_token )
    .then((response) => {
            store.commit('login', response.data)
            failedRequest.response.config.headers['Authorization'] = 'Bearer ' + store.getters.access_token;
            router.push('/')
        })
    .catch(function () {
        store.commit('logout')
        router.push('/login')
    })
    };

createAuthRefreshInterceptor(api, refreshAuthLogic, {statusCodes: [401, 403], pauseInstanceWhileRefreshing: true});

axiosRetry(api, { retries: 3 });

export const app = createApp(App);

app.use(store)
app.use(router)
app.use(PrimeVue)
app.use(ToastService)
app.mount('#app')


