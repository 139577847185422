import { createStore } from 'vuex'

export default createStore({
  state: {
    access_token: "",
    refresh_token: "",
    queries: []
  },
  getters:{
    access_token(state) {
      return state.access_token
    },
    refresh_token(state) {
      return state.refresh_token
    }
  },
  mutations: {
    login(state,data) {
      state.access_token = data.access_token;
      state.refresh_token = data.refresh_token;
      localStorage.setItem('access_token', data.access_token);
      localStorage.setItem('refresh_token', data.refresh_token);
    },
    logout(state){
      state.access_token ="";
      state.refresh_token ="";
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
    },
    initialiseStore(state) {
      if(localStorage.getItem('access_token')){
        state.access_token = localStorage.getItem('access_token');
        state.refresh_token = localStorage.getItem('refresh_token');
       }
    },
    isSignedIn(state) {
      return state.access_token !== ""
    },
    getToken(state) {
      return state.access_token
    },
    getQueries(state) {
      if(localStorage.getItem('queries')) {
        state.queries = localStorage.getItem('queries')
      }
      return state.queries
    },
    saveQuery(state, query) {
      if(state.queries.length > 5) {
        state.queries.pop()
      }
      state.queries.push(query)
      localStorage.setItem('queries', state.queries);
    }
  },
  actions: {
  },
  modules: {
  }
})
