// myToastService.ts
import {ToastSeverity} from 'primevue/api';
import {app} from '../main';

const lifeTime = 10000;

export function info(title, body) {
  app.config.globalProperties.$toast.add({severity: ToastSeverity.INFO, summary: title, detail: body, life: lifeTime})
}

export function warning(title, body) {
  app.config.globalProperties.$toast.add({severity: ToastSeverity.WARN, summary: title, detail: body, life: lifeTime})
}

export function error(title, body) {
  app.config.globalProperties.$toast.add({severity: ToastSeverity.ERROR, summary: title, detail: body, life: lifeTime})
}