<template>
    <Map mapType="LSA" public/>
</template>

<script>
import Map from '../components/Map.vue'

export default {
    name: 'LsaMap',
    components: { Map }
};
</script>
